import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ToDisplayValuePipe } from '@library/utils/pipes/to-display-value.pipe';
import { ToUserUnitPipe } from '@library/utils/pipes/to-user-unit.pipe';
import { AsyncLangPipe } from './asynclang.pipe';
import { IsDefinedPipe } from './is-defined.pipe';
import { LangPipe } from './lang.pipe';
import { SanitizePipe } from './sanitize.pipe';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    LangPipe,
    AsyncLangPipe,
    SanitizePipe,
    ToDisplayValuePipe,
  ],
  exports: [
    LangPipe,
    AsyncLangPipe,
    SanitizePipe,
    CommonModule,
    ToUserUnitPipe,
    ToDisplayValuePipe,
    IsDefinedPipe,
  ],
  declarations: [
    LangPipe,
    AsyncLangPipe,
    SanitizePipe,
    ToUserUnitPipe,
    ToDisplayValuePipe,
    IsDefinedPipe,
  ]
})
export class PipesModule {}

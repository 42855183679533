import { Actions, ofType } from '@ngrx/effects';
import { ActionCreator } from '@ngrx/store';
import { of, Subject } from 'rxjs';
import { mapTo, startWith, switchMap } from 'rxjs/operators';

/**
 * Purpose: handle the loading state of a button
 *
 * How to use it:
 * In the component.ts:
 *    - Instanciate a new Loader, giving it this.actions as a parameter
 *      ex: confirmLoader = new Loader(this.actions);
 *    - Use the loadUntil method whenever you want the button to load, giving it an array of actions
 *      The button will stop spinning when these actions are fired
 *      ex: confirmLoader.loadUntil([GetHomesFailure, GetHomesSuccess]);
 * In the component.html:
 *    - Use the loading$ attribute of your loader as the isLoading input of your button
 *      ex: <el-confirm-button [isLoading]="confirmLoader.loading$ | async">
 */
export class Loader {
  /**
   * Observer: will give a new value to the loading$ observable, triggering the loading of the button
   */
  private loading = new Subject<string[] | ActionCreator[]>();

  /**
   * Observable emitting true upon receiving a new value and then false when actions of the specified type are fired
   * Represents the loading of the button
   */
  public loading$ = this.loading.pipe(
    switchMap(actionsToLoadUntil => {
      if (!actionsToLoadUntil) {
        return of(false);
      }
      else {
        return this.actions.pipe(
          ofType(...actionsToLoadUntil),
          mapTo(false),
          startWith(true),
        );
      }
    })
  );

  constructor(private actions: Actions) { }

  /**
   * Gives a new value to the loading$ observable: tells it to load until the action passed as parameters are fired
   * @param actionsToLoadUntil Array of string containing the actions that make the button stop loading
   */
  loadUntil(actionsToLoadUntil: string[] | ActionCreator[]) {
    this.loading.next(actionsToLoadUntil);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { OverflowEllipsisModule } from '../overflow-ellipsis/overflow-ellipsis.module';
import { AtomsModule } from '@library/ui/atoms/atoms.module';

@NgModule({
  imports: [
    CommonModule,
    OverflowEllipsisModule,
    AtomsModule
  ],
  declarations: [
    HeaderComponent
  ],
  exports: [
    HeaderComponent
  ]
})

export class HeaderModule {}

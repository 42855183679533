import { Store, select } from '@ngrx/store';
import { GetConfig, EnumConfigActions, SetDarkModeStatus, SetWebappMode } from './config.action';
import { Injectable } from '@angular/core';
import * as ConfigSelectors from './config.selectors';
import { ofType, Actions } from '@ngrx/effects';
import { map, take } from 'rxjs/operators';
import { EnumEnvs } from './config.interface';
import { SettingsState } from '@library/utils/interfaces/settings-state.interface';

@Injectable({providedIn: 'root'})
export class ConfigFacade {
  config$ = this.store.pipe(select(ConfigSelectors.getAllConfig));
  appId$ = this.store.pipe(select(ConfigSelectors.getAppId));
  appPrefix$ = this.store.pipe(select(ConfigSelectors.getAppPrefix));
  environment$ = this.store.pipe(select(ConfigSelectors.getEnvironment));
  isProductionEnv$ = this.environment$.pipe(map(env => env === EnumEnvs.PRODUCTION));
  isBetaEnv$ = this.environment$.pipe(map(env => env === EnumEnvs.BETA));
  isIntegrationEnv$ = this.environment$.pipe(map(env => env === EnumEnvs.INTEGRATION));
  isLocalEnv$ = this.environment$.pipe(map(env => env === EnumEnvs.LOCAL));
  webappMode$ = this.store.pipe(select(ConfigSelectors.getWebappMode));
  darkModeEnabled$ = this.store.pipe(select(ConfigSelectors.darkModeEnabled));

  constructor(
        protected store: Store<SettingsState>,
        protected actions$: Actions,
  ) { }

  loadConfig() {
    this.store.dispatch(new GetConfig());
    return this.actions$.pipe(
      ofType(EnumConfigActions.GetConfigSuccess)
    );
  }

  setDarkModeStatus(status: boolean) {
    status = status || false;
    this.store.dispatch( new SetDarkModeStatus(status));
  }

  setWebappMode() {
    this.store.dispatch(new SetWebappMode());
  }

  getWebappMode() {
    let webappMode = false;
    this.webappMode$.pipe(take(1))
      .subscribe(m => webappMode = m);
    return webappMode;
  }
}

import { Directive, ElementRef, Input, Optional } from '@angular/core';
import { HomesFacade } from '@library/store/homes/homes.facade';
import { EnumMeasureTypes } from '@library/store/measures/measures.interface';
import { UserFacade } from '@library/store/user/user.facade';
import { Enums } from '@library/utils/constants/Enums.constant';
import { isDefined } from '@library/utils/helpers/store/is-defined';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[unit]',
})
export class UnitDirective {

  @Input() measureType: EnumMeasureTypes;
  @Input() value: number;

  private previousText: string = null;

  constructor(
    public hostElement: ElementRef<HTMLElement>,
    @Optional() public homesFacade: HomesFacade,
    public userFacade: UserFacade,
  ) { }

  async ngOnChanges() {
    let unit = '';
    const user = await firstValueFrom(this.userFacade.currentUser$.pipe(isDefined()));
    switch (this.measureType) {
      case EnumMeasureTypes.CO2:
      case EnumMeasureTypes.MAX_CO2:
      case EnumMeasureTypes.MIN_CO2:
        unit = 'ppm';
        break;
      case EnumMeasureTypes.TEMPERATURE:
      case EnumMeasureTypes.MAX_TEMPERATURE:
      case EnumMeasureTypes.MIN_TEMPERATURE:
      case EnumMeasureTypes.SP_TEMPERATURE:
      case EnumMeasureTypes.SP_TEMPERATURE_OFFSET_NEGATIVE:
      case EnumMeasureTypes.SP_TEMPERATURE_OFFSET_POSITIVE:
        unit = user.unit_system ? UNITS.TEMPERATURE[user.unit_system] : UNITS.TEMPERATURE[Enums.SystemUnit.METRIC];
        break;
      case EnumMeasureTypes.HUMIDITY:
      case EnumMeasureTypes.MAX_HUMIDITY:
      case EnumMeasureTypes.MIN_HUMIDITY:
      case EnumMeasureTypes.PROB_RAIN:
      case EnumMeasureTypes.PERCENT_BOILER_ON:
      case EnumMeasureTypes.HEATING_POWER_REQUEST:
        unit = '%';
        break;
      case EnumMeasureTypes.PRESSURE:
        unit = UNITS.PRESSURE[user.unit_pressure ?? 0];
        break;
      case EnumMeasureTypes.WIND_STRENGTH:
      case EnumMeasureTypes.GUST_STRENGTH:
        unit = UNITS.WIND_SPEED[user.unit_wind];
        break;
      case EnumMeasureTypes.WIND_ANGLE:
      case EnumMeasureTypes.GUST_ANGLE:
        unit = '';
        break;
      case EnumMeasureTypes.SUM_RAIN:
        unit = UNITS.RAIN[user.unit_system];
        break;
      case EnumMeasureTypes.NOISE:
      case EnumMeasureTypes.MAX_NOISE:
      case EnumMeasureTypes.MIN_NOISE:
        unit = 'db';
        break;
      case EnumMeasureTypes.SUM_ENERGY_ELEC_NO_CONTRACT:
      case EnumMeasureTypes.SUM_ENERGY_ELEC_BASE:
      case EnumMeasureTypes.SUM_ENERGY_ELEC_PEAK:
      case EnumMeasureTypes.SUM_ENERGY_ELEC_OFFPEAK:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_NO_CONTRACT:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_BASE:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PEAK:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_OFFPEAK:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_SUPER_OFFPEAK:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_WEEKEND:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_FREE:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_CUSTOM1:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_CUSTOM2:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_CUSTOM3:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_CUSTOM4:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_CUSTOM5:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_CUSTOM6:
      case EnumMeasureTypes.SUM_ENERGY_RESELL_TO_GRID:
      case EnumMeasureTypes.SUM_ENERGY_SELF_CONSUMPTION:
        unit = 'kWh';
        break;
      case EnumMeasureTypes.POWER_PROD_ELEC:
        unit = (this.value > 1000) ? 'kW' : 'W';
        break;
      case EnumMeasureTypes.SUM_FLUID_CONSUMPTION_GAS:
        unit = 'dm\xB3';
        break;
      case EnumMeasureTypes.SUM_FLUID_CONSUMPTION_HOT_WATER:
      case EnumMeasureTypes.SUM_FLUID_CONSUMPTION_COLD_WATER:
        unit = 'L';
        break;
      case EnumMeasureTypes.SUM_ENERGY_PRICE_BASE:
      case EnumMeasureTypes.SUM_ENERGY_PRICE_PEAK:
      case EnumMeasureTypes.SUM_ENERGY_PRICE_OFFPEAK:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_BASE:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_PEAK:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_OFFPEAK:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_SUPER_OFFPEAK:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_WEEKEND:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_FREE:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM1:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM2:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM3:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM4:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM5:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM6:
      case EnumMeasureTypes.SUM_ENERGY_RESELL_TO_GRID_PRICE:
      case EnumMeasureTypes.SUM_FLUID_PRICE_HOT_WATER:
      case EnumMeasureTypes.SUM_FLUID_PRICE_COLD_WATER:
      case EnumMeasureTypes.SUM_FLUID_PRICE_GAS:
        this.homesFacade.currencySymbol$.pipe(take(1)).subscribe(s => unit = s);
        break;
    }

    (this.hostElement.nativeElement.firstElementChild as HTMLParagraphElement).innerText = unit;
  }

}

export const UNITS = {
  WIND_SPEED: {
    [Enums.WindUnit.KMH]: 'km/h',
    [Enums.WindUnit.MPH]: 'mph',
    [Enums.WindUnit.MS]: 'm/s',
    [Enums.WindUnit.BEAUFORT]: 'bf',
    [Enums.WindUnit.KNOT]: 'kt',
  },
  TEMPERATURE: {
    [Enums.SystemUnit.METRIC]: '°C',
    [Enums.SystemUnit.IMPERIAL]: '°F',
  },
  RAIN: {
    [Enums.SystemUnit.METRIC]: 'mm',
    [Enums.SystemUnit.IMPERIAL]: 'in',
  },
  PRESSURE: {
    [Enums.PressureUnit.MBAR]: 'mb',
    [Enums.PressureUnit.MERCURY]: 'inHg',
    [Enums.PressureUnit.TORR]: 'mmHg',
  }
};

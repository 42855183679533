import { filter, Observable } from 'rxjs';

export function isDefined<T>() {
  return function (source$: Observable<T>): Observable<T> {
    return source$.pipe(
      filter(v => v !== null && typeof v !== 'undefined'),
    );
  };
}

export function isDefinedCombine<T>() {
  return function (source$: Observable<Array<T>>): Observable<Array<T>> {
    return source$.pipe(
      filter(values => values.every(v => v !== null && typeof v !== 'undefined')),
    );
  };
}
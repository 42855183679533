export interface LangState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
  reg_locale: string;
}

// const reg_locale = new URLSearchParams(document.location.search).get('reg_locale') || 'en_US';

export const initialLangState: LangState = {
  app: {},
  reg_locale: 'en_US'
};

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputLabelComponent } from './input-label/input-label.component';
import { AtomsModule } from '@library/ui/atoms/atoms.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconModule } from '@library/ui/atoms/icon/icon.module';
import { InputTextComponent } from './input-text/input-text.component';

@NgModule({
  declarations: [
    InputLabelComponent,
    InputTextComponent
  ],
  imports: [
    CommonModule,
    AtomsModule,
    FormsModule,
    ReactiveFormsModule,
    IconModule
  ],
  exports: [
    InputLabelComponent,
    InputTextComponent
  ]
})
export class InputModule { }

import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as LangSelectors from './lang.selectors';
import * as LangActions from './lang.actions';
import { Actions } from '@ngrx/effects';
import { SettingsState } from '@library/utils/interfaces/settings-state.interface';
@Injectable({providedIn: 'root'})
export class LangFacade {
  lang$ = this.store.pipe(select(LangSelectors.getAllLangs));
  regLocale$ = this.store.pipe(select(LangSelectors.getRegLocale));

  constructor(
        private store: Store<SettingsState>,
        protected actions$: Actions,
  ) { }

  translate(value, args: string[] = []) {
    return this.store.pipe(select(LangSelectors.getTranslation, {value, args}));
  }

  loadLangs(aliases: {name: string, locale: string}[], locale?: string) {
    this.store.dispatch(new LangActions.LoadLangs({
      aliases: aliases,
      locale: locale
    }));
  }

  setLangs(langs: {[key: string]: string}, regLocale?: string) {
    this.store.dispatch(
      new LangActions.LoadLangsSuccess({ ...langs, reg_locale: regLocale })
    );
  }
}

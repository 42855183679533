import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionItemComponent } from './accordion-item.component';
import { AccordionComponent } from './accordion.component';
import { AtomsModule } from '@library/ui/atoms/atoms.module';
import { LayoutModule } from '@library/ui/layout/layout.module';

@NgModule({
  declarations: [
    AccordionItemComponent,
    AccordionComponent
  ],
  imports: [
    CommonModule,
    AtomsModule,
    LayoutModule
  ],
  exports: [
    AccordionItemComponent,
    AccordionComponent
  ]
})
export class AccordionModule { }

import { CommonModule } from '@angular/common';
import { provideHttpClient } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HOMES_FACADE } from '@library/store/homes/homes.facade';
import { MODULES_FACADE } from '@library/store/modules/modules.facade';
import { UserFacade } from '@library/store/user/user.facade';
import { USE_SHAREIT } from '@library/ui/directives/share/share.directive';
import { HeaderModule } from '@library/ui/layout/header/header.module';
import { MainModule } from '@library/ui/layout/main/main.module';
import { MoleculesModule } from '@library/ui/molecules/molecules.module';
import { UIModule } from '@library/ui/ui.module';
import { GlobalErrorHandler } from '@library/utils/classes/settings-error-handler';
import { PipesModule } from '@library/utils/pipes/pipes.module';
import { MAPBOX_ACCESS_TOKEN } from '@library/utils/services/mapbox.service';
import { NavigationCounter } from '@library/utils/services/navigation-counter.service';
import { NOTIFICATIONS, NotificationService } from '@library/utils/services/notifications.service';
import { UnitConverterService } from '@library/utils/services/unit-converter.service';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { HomesFacadeVaillant } from './stores/homes/homes.facade';
import { HomesResolver } from './stores/homes/homes.resolver';
import { InitLoader, InitLoaderFactory } from './stores/init/init.loader';
import { LoadersModule } from './stores/init/loaders.module';
import { EnumModulesActionsVaillant } from './stores/modules/modules.actions';
import { ModulesFacadeVaillant } from './stores/modules/modules.facade';
import { VaillantRoomsFacade } from './stores/rooms/rooms.facade';
import { StateModule } from './stores/state.module';
import { HomeRoutesViewComponent } from './views/home-view/home-routes-view/home-routes-view.component';
import { HomeViewComponent } from './views/home-view/home-view.component';




const appServices = [
  CookieService,
  NotificationService,
  NavigationCounter,
  DeviceDetectorService,
  UnitConverterService
];

const providers = [
  HomesResolver,
  UserFacade,
  {
    provide: HOMES_FACADE,
    useExisting: HomesFacadeVaillant
  },
  {
    provide: MODULES_FACADE,
    useExisting: ModulesFacadeVaillant
  },
  VaillantRoomsFacade,
  {
    provide: APP_INITIALIZER,
    useFactory: InitLoaderFactory,
    multi: true,
    deps: [InitLoader]
  },
  {
    provide: USE_SHAREIT,
    useValue: true
  },
  {
    provide: MAPBOX_ACCESS_TOKEN,
    useValue: environment.mapboxAccessToken
  },
  {
    provide: NOTIFICATIONS,
    useValue: [
      {
        message: 'settings-vaillant.__SERVICE_CONTACT_RESET', // CROWDIN needs #crowdin
        iconName: 'check',
        timeToDisplay: 2000,
        actionType: EnumModulesActionsVaillant.ChangeContactServiceSuccess,
        notificatonType: 'success'
      },
      {
        message: 'settings-therm.__RESET_HEATING_CURVE', // CROWDIN needs #crowdin
        iconName: 'check',
        timeToDisplay: 2000,
        actionType: EnumModulesActionsVaillant.ResetHeatingCurveSuccess,
        notificatonType: 'success'
      }
    ]
  },
  {
    provide: ErrorHandler,
    useClass: GlobalErrorHandler
  }
];

@NgModule({ declarations: [
        AppComponent,
        HomeRoutesViewComponent,
        HomeViewComponent
    ],
    bootstrap: [AppComponent], imports: [StateModule,
        BrowserAnimationsModule,
        HeaderModule,
        MainModule,
        AppRoutingModule,
        BrowserModule,
        LoadersModule,
        PipesModule,
        CommonModule,
        ComponentsModule,
        UIModule,
        MoleculesModule], providers: [
        ...appServices,
        ...providers,
        provideHttpClient()
    ] })
export class AppModule { }

type IdentifiableEntity = {
  id: string
};

export type EntitiesUpdate<T> = {
  [id: string]: Partial<T>
}; // the key is the entity id while the value is the update for the entity

export function getUpdatedCollection<T extends IdentifiableEntity>(collection: T[], update: EntitiesUpdate<T>): T[] {
  return collection.map(entity => {
    if (update[entity.id]) {
      entity = {
        ...entity,
        ...update[entity.id],
      };
    }
    return entity;
  });
}

/* *
  * Update modules data with new data
  * @param modules: Old modules data ( all modules from the state )
  * @param payload: New modules data (from homestatus)
  * @returns new modules array with merged data
* */

export function collectionToEntitiesUpdate<T extends IdentifiableEntity>(collection: T[] = []): EntitiesUpdate<T> {
  return collection.reduce((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {});
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IsLoadingDirective } from './is-loading/is-loading.directive';
import { ShareDirective } from './share/share.directive';
import { UnitDirective } from './unit/unit.directive';

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    ShareDirective,
    UnitDirective,
    IsLoadingDirective,
  ],
  declarations: [
    ShareDirective,
    UnitDirective,
    IsLoadingDirective,
  ]
})
export class DirectivesModule { }

import { getCurrentHomeId } from '@library/store/homes/homes.selector';
import { createSelector } from '@ngrx/store';
import { SchedulesState } from './schedules.state';

const schedulesState = (state) => state.schedules as SchedulesState;

export const getQuizzData = createSelector(
  schedulesState,
  state => {
    return state.scheduleToAdd.quizzData;
  }
);

export const currentHomeSchedules = createSelector(
  getCurrentHomeId,
  schedulesState,
  (currentHomeId, state) => {
    return state.schedules.filter(schedule => schedule.home_id === currentHomeId);
  }
);

export const currentSelectedThermSchedule = createSelector(
  currentHomeSchedules,
  schedules => {
    return schedules.find(schedule => schedule.type === 'therm' && schedule.selected);
  }
);

export const currentSelectedCoolingSchedule = createSelector(
  currentHomeSchedules,
  schedules => {
    return schedules.find(schedule => schedule.type === 'cooling' && schedule.selected);
  }
);

export const currentSelectedAutoSchedule = createSelector(
  currentHomeSchedules,
  schedules => {
    return schedules.find(schedule => schedule.type === 'auto' && schedule.selected);
  }
);

export const thermSchedules = createSelector(
  currentHomeSchedules,
  schedules => {
    return schedules.filter(schedule => schedule.type === 'therm');
  }
);
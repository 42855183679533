import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailBadgeComponent } from './email-badge.component';
import { BadgeModule } from '@library/ui/atoms/badge/badge.module';

@NgModule({
  declarations: [EmailBadgeComponent],
  imports: [
    CommonModule,
    BadgeModule,
  ],
  exports: [
    EmailBadgeComponent
  ]
})
export class EmailBadgeModule { }

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
/**
 * Fundamental Button Element
 *
 * @deprecated [#1] Deprecating in favor of using native button element
 * [#2] Will be removed from project once confirmed all projects no longer use Fun Button
 * [#3] RIP FunButton
 *
 * @param text
 */
export class ButtonComponent {
    /**
     * Text to display
     */
    @Input() text: string;

    /**
     * Specific class
     */
    @Input() styleClass: string = '';

    /**
     * Name of the icon
     */
    @Input() hasIcon: string;

    /**
     * Loading state for requests made from Button
     */
    @Input() isLoading: boolean;

    /**
     * Button type: can be submit (default), reset, button
     */
    @Input() buttonType: string;

    /**
     * Set default value for disabled attribute
     */
    @Input() disabled: boolean;
}

/**
 * Used in Settings Security
 * Used in Settings Legrand
 */

import {
  transition,
  trigger,
  query,
  style,
  animate,
  group
} from '@angular/animations';

const forward = [
  query(':self, main :enter, main :leave',
    style({ position: 'fixed', height: '100%', width: '100%', maxWidth: '600px' }), { optional: true }),
  query('main', style({transform: 'translate3d(0,0,0)'}), {optional: true}),
  query('[role=header], [role=footer], main', style({ position: 'relative' }), { optional: true }),
  group([
    query('main :enter', [
      style({ transform: 'translateX(100%)' }),
      animate('.35s cubic-bezier(0.190, 1.000, 0.220, 1.000)', style({ transform: 'translateX(0%)' }))
    ], { optional: true }),
    query('main :leave', [
      style({ transform: 'translateX(0%)' }),
      animate('.35s cubic-bezier(0.190, 1.000, 0.220, 1.000)', style({ transform: 'translateX(-100%)' }))
    ], { optional: true }),
  ])
];

const backward = [
  query(':self, main :enter, main :leave',
    style({ position: 'fixed', height: '100%', width: '100%', maxWidth: '600px' }), { optional: true }),
  query('main', style({transform: 'translate3d(0,0,0)'}), {optional: true}),
  query('[role=header], [role=footer], main', style({ position: 'relative' }), { optional: true }),
  group([
    query('main :enter', [
      style({ transform: 'translateX(-100%)' }),
      animate('.35s cubic-bezier(0.190, 1.000, 0.220, 1.000)', style({ transform: 'translateX(0%)' }))
    ], { optional: true }),
    query('main :leave', [
      style({ transform: 'translateX(0%)' }),
      animate('.35s cubic-bezier(0.190, 1.000, 0.220, 1.000)', style({ transform: 'translateX(100%)' }))
    ], { optional: true }),
  ])
];

/**
 * Backward (Forward) and BackwardAgain (ForwardAgain) are used alternatively to keep going back (ahead)
 */
export const slideInAnimation =
  trigger('routeAnimations', [
    transition('* => Backward', backward),
    transition('* => BackwardAgain', backward),

    transition('* => Forward', forward),
    transition('* => ForwardAgain', forward),
  ]);

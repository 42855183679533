export const countryPrice = [
  { code: 'AL', currencySymbol: 'L' },
  { code: 'DZ', currencySymbol: 'دج' },
  { code: 'AU', currencySymbol: '$' },
  { code: 'AT', currencySymbol: '€' },
  { code: 'BE', currencySymbol: '€' },
  { code: 'BA', currencySymbol: 'MK' },
  { code: 'BR', currencySymbol: 'R$' },
  { code: 'BG', currencySymbol: 'лв' },
  { code: 'CA', currencySymbol: '$' },
  { code: 'CL', currencySymbol: '$' },
  { code: 'CO', currencySymbol: '$' },
  { code: 'CR', currencySymbol: '₡' },
  { code: 'HR', currencySymbol: 'kn' },
  { code: 'CZ', currencySymbol: 'Kč' },
  { code: 'DK', currencySymbol: 'kr' },
  { code: 'EG', currencySymbol: 'ج.م' },
  { code: 'EE', currencySymbol: '€' },
  { code: 'FI', currencySymbol: '€' },
  { code: 'FR', currencySymbol: '€' },
  { code: 'DE', currencySymbol: '€' },
  { code: 'GB', currencySymbol: '£' },
  { code: 'GR', currencySymbol: '€' },
  { code: 'HU', currencySymbol: 'Ft' },
  { code: 'IN', currencySymbol: 'Rs.' },
  { code: 'IE', currencySymbol: '€' },
  { code: 'IL', currencySymbol: '₪' },
  { code: 'IT', currencySymbol: '€' },
  { code: 'JP', currencySymbol: '¥' },
  { code: 'KR', currencySymbol: '₩' },
  { code: 'XK', currencySymbol: '€' },
  { code: 'LV', currencySymbol: '€' },
  { code: 'LB', currencySymbol: 'L.L' },
  { code: 'LT', currencySymbol: '€' },
  { code: 'LU', currencySymbol: '€' },
  { code: 'MY', currencySymbol: 'RM' },
  { code: 'MU', currencySymbol: '₨' },
  { code: 'MX', currencySymbol: '$' },
  { code: 'ME', currencySymbol: '€' },
  { code: 'MA', currencySymbol: 'د.م.' },
  { code: 'MM', currencySymbol: 'K' },
  { code: 'NL', currencySymbol: '€' },
  { code: 'NZ', currencySymbol: '$' },
  { code: 'NO', currencySymbol: 'kr' },
  { code: 'MK', currencySymbol: 'MKD' },
  { code: 'PL', currencySymbol: 'zł' },
  { code: 'PT', currencySymbol: '€' },
  { code: 'RO', currencySymbol: 'L' },
  { code: 'RU', currencySymbol: 'руб' },
  { code: 'SA', currencySymbol: 'ر.س' },
  { code: 'RS', currencySymbol: 'd./д.' },
  { code: 'SG', currencySymbol: 'S$' },
  { code: 'SK', currencySymbol: '€' },
  { code: 'SI', currencySymbol: '€' },
  { code: 'ZA', currencySymbol: 'R' },
  { code: 'ES', currencySymbol: '€' },
  { code: 'SE', currencySymbol: 'kr' },
  { code: 'CH', currencySymbol: 'CHF' },
  { code: 'TH', currencySymbol: '฿' },
  { code: 'TR', currencySymbol: 'YTL' },
  { code: 'AE', currencySymbol: 'د.إ' },
  { code: 'VN', currencySymbol: '₫' },
  { code: 'US', currencySymbol: '$' },
  { code: 'HK', currencySymbol: 'HK$' },
  // { code: 'MQ', currencySymbol: '€' },
  // { code: 'GF', currencySymbol: '€' },
  // { code: 'RE', currencySymbol: '€' },
  // { code: 'YT', currencySymbol: '€' },
];


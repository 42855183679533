import { Component, OnInit, HostBinding, Inject } from '@angular/core';
import { slideInAnimation } from './route-animation';
import { UserAgentService } from '@library/utils/services/user-agent.service';
import { tap, map, skip } from 'rxjs/operators';
import { NavigationCounter, EnumNavigationDirections, GLOBAL_NAVCOUNTER } from '@library/utils/services/navigation-counter.service';
import { ConfigFacade } from '@library/store/config/config.facade';

@Component({
  selector: 'lay-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: [slideInAnimation],
})
export class MainComponent implements OnInit {

  lastTransition = null;

  animation$ = this.counter.currentDirection$.pipe(
    skip(1), // neglect the navigation event of entering the web app
    map((direction) => {
      if (direction === EnumNavigationDirections.Forward) {
        return this.lastTransition === 'Forward' ? 'ForwardAgain' : 'Forward';
      }
      else {
        return this.lastTransition === 'Backward' ? 'BackwardAgain' : 'Backward';
      }
    }),
    tap(transition => {
      this.lastTransition = transition;
    })
  );

  constructor(
    public userAgent: UserAgentService,
    @Inject(GLOBAL_NAVCOUNTER) private counter: NavigationCounter,
    public configFacade: ConfigFacade,
  ) {}

  @HostBinding('class') class = 'is-fullheight is-fullwidth';

  ngOnInit() {
    this.setDeviceClasses();
    this.counter.startCounting();
  }

  isWebappMode() {
    return this.configFacade.getWebappMode();
  }

  /**
   * Add device environement classes to body
   * depends on user agent
   */
  setDeviceClasses() {
    if (this.userAgent.isNativeApp()) {
      document.body.classList.add('is-native');
    }

    if (this.userAgent.isMobile()) {
      document.body.classList.add('is-mobile');
    }
    else {
      document.body.classList.add('is-desktop');
    }

    if (this.userAgent.isIos()) {
      document.body.classList.add('is-ios');
      document.body.classList.add(
        `is-ios-version-${this.userAgent.iosVersion().trim()}`
      );
    }

    if (this.userAgent.isAndroid()) {
      document.body.classList.add('is-android');
    }
  }
}

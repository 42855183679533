import { Module } from '@library/store/modules/modules.interface';
import { ModulesState } from '@library/store/modules/modules.state';
import { RoomsState } from '@library/store/rooms/rooms.state';
import { SettingsState } from '@library/utils/interfaces/settings-state.interface';
import { createSelector } from '@ngrx/store';
import { Room } from './rooms.interface';

const roomsState = (state: SettingsState) => state.rooms;
const homesState = (state: SettingsState) => state.homes;
const modulesState = (state: SettingsState) => state.modules;

export const getModules = createSelector(
  modulesState,
  (state: ModulesState) => {
    return state.all;
  }
);

export const getCurrentHomeId = createSelector(
  homesState,
  state => {
    return state.currentHomeId;
  }
);

export const getRooms = createSelector(
  roomsState,
  (state: RoomsState) => {
    return state.all;
  }
);

export const getCurrentRoomId = createSelector(
  roomsState,
  (state: RoomsState) => {
    return state.currentRoomId;
  }
);

export const getCurrentHomeRooms = createSelector(
  getCurrentHomeId,
  getRooms,
  (currentHomeId: string, rooms: Room[]) => {
    return rooms.filter(room => room.homeId === currentHomeId);
  }
);
// @deprecated
export const getcurrentHomeRooms = getCurrentHomeRooms;

export const isLoadingRooms = createSelector(
  roomsState,
  (state: RoomsState) => {
    return state.loading;
  }
);

export const getCurrentRoom = createSelector(
  getRooms,
  getCurrentRoomId,
  (rooms: Room[], id: string) => {
    return rooms.find(room => room.id === id);
  }
);

export const getRoomById = (id: string) => createSelector(
  getRooms,
  (rooms: Room[]) => {
    return rooms.find(room => room.id === id);
  }
);

export const getCurrentTemperature = createSelector(
  getCurrentRoom,
  (room: Room) => {
    return room?.therm_measured_temperature ?? null;
  }
);

/**
 * The condition is for the room which not have modules to not print an error when we check
 * if the id is on the module list
 */
export const getRoomModules = createSelector(
  getModules,
  getCurrentRoom,
  (modules: Module[], room: Room) => {
    if (!room || !room.modules) {
      return null;
    }
    return modules.filter(module => room.modules.includes(module.id));
  }
);

export const getTrueTemperatureAvailable = createSelector(
  getCurrentRoom,
  (room: Room) => {
    return room?.true_temperature_available ?? null;
  }
);

export const hasValve = createSelector(
  getRoomModules,
  (modules: Module[] = []) => {
    return modules?.some(mod => mod.type === 'NRV');
  }
);

export const isCurrentRoomReachable = createSelector(
  getCurrentRoom,
  (room: Room) => {
    return room.reachable;
  }
);

export const getRoomGateway = createSelector(
  getModules,
  getCurrentRoom,
  (modules: Module[], room: Room) => {
    return modules.find(mod => mod.id === room.therm_relay);
  }
);


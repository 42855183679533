import { Action } from '@ngrx/store';

export enum EnumPageLoaderActions {
  ShowPageLoader = '[APP] Show page loader',
  HidePageLoader = '[APP] Hide page loader',
  HidePageLoaderSuccess = '[APP] Hide page loader success',
}

export class ShowPageLoader implements Action {
  public readonly type = EnumPageLoaderActions.ShowPageLoader;
}

export class HidePageLoader implements Action {
  public readonly type = EnumPageLoaderActions.HidePageLoader;
}

export class HidePageLoaderSuccess implements Action {
  public readonly type = EnumPageLoaderActions.HidePageLoaderSuccess;
}

export type PageLoaderActions = ShowPageLoader
  | HidePageLoader
  | HidePageLoaderSuccess
;

import { NgModule } from '@angular/core';
import { ActivableIconTextComponent } from './activable-icon-text.component';
import { AtomsModule } from '@library/ui/atoms/atoms.module';

@NgModule({
  declarations: [
    ActivableIconTextComponent
  ],
  exports: [
    ActivableIconTextComponent
  ],
  imports: [
    AtomsModule
  ]
})
export class ActivableIconTextModule { }

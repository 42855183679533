import { NgModule } from '@angular/core';
import { BadgeModule } from './badge/badge.module';
import { ButtonModule } from './button/button.module';
import { IconModule } from './icon/icon.module';
import { ImageModule } from './image/image.module';
import { LoadingCircleModule } from './loading-circle/loading-circle.module';
import { RadioModule } from './radio/radio.module';
import { RangeModule } from './range/range.module';
import { SelectModule } from './select/select.module';
import { SliderModule } from './slider/slider.module';
import { ControllerSwitchModule } from './controller-switch/controller-switch.module';
import { TagModule } from './tag/tag.module';
import { TextModule } from './text/text.module';
import { SwitchModule } from './switch/switch.module';

const Modules = [
  BadgeModule,
  ButtonModule,
  IconModule,
  ImageModule,
  RadioModule,
  RangeModule,
  SelectModule,
  SliderModule,
  ControllerSwitchModule,
  SwitchModule,
  TagModule,
  TextModule,
  LoadingCircleModule,
];

@NgModule({
  imports: Modules,
  exports: Modules,
})
export class AtomsModule { }

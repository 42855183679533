import { initialConfigState, ConfigState } from './config.state';
import { ConfigActions, EnumConfigActions } from './config.action';

export function ConfigReducers(state = initialConfigState, action: ConfigActions): ConfigState {
  switch (action.type) {
    case EnumConfigActions.GetConfigSuccess: {
      return {
        ...state,
        ...action.payload
      };
    }

    case EnumConfigActions.SetDarkModeStatus: {
      return {
        ...state,
        darkModeEnabled: action.payload
      };
    }

    case EnumConfigActions.SetWebappMode: {
      return {
        ...state,
        webappMode: true,
      };
    }

    default:
      return state;
  }
}

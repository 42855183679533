export function downloadFile(options) {
  // Handles saving files for IE11, since IE11 doesn't support "download" property on links
  // Otherwise, normal flow
  const blob = new Blob([options.data], { type: 'application/octet-stream' });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((navigator as any).msSaveOrOpenBlob) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (navigator as any).msSaveOrOpenBlob(blob, options.filename);
  }
  else {
    const link = document.createElement('a');
    link.addEventListener('click',
      ($event) => {
        $event.stopPropagation();
      }
    );
    // Give it some invisibility
    link.style.setProperty('display', 'none');
    // Create the url fro the link with the data
    const url = window.URL.createObjectURL(options.data);
    // Set link properties
    link.href = url;
    link.download = options.filename;
    // Simulate a click on it
    document.getElementsByTagName('body')[0].appendChild(link);
    link.click();
    // Remove the link after the operation is done
    setTimeout(() => {
      document.getElementsByTagName('body')[0].removeChild(link);
      window.URL.revokeObjectURL(url);
    }, 100);
  }
}

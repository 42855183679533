import { Action } from '@ngrx/store';

export enum EnumLangActions {
  LoadLangs = '[Lang] Load Langs',
  LoadLangsSuccess = '[Lang] Load Langs Success',
  LoadLangsFailure = '[Lang] Load Langs Failure',
}

export class LoadLangs implements Action {
  public readonly type = EnumLangActions.LoadLangs;
  constructor(public payload: string[] | string | object) { }
}

export class LoadLangsSuccess implements Action {
  public readonly type = EnumLangActions.LoadLangsSuccess;
  constructor(public payload) { }
}

export class LoadLangsFailure implements Action {
  public readonly type = EnumLangActions.LoadLangsFailure;
  constructor(public payload) { }
}

export type LangActions =
  LoadLangs |
  LoadLangsSuccess |
  LoadLangsFailure
  ;

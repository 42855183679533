import { ErrorHandler, Injectable } from '@angular/core';

const lastForcedChunkReloadKey = 'last_forced_chunk_reload';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler extends ErrorHandler {

  constructor() {
    super();
  }

  handleError(error: Error): void {
    /**
     * If we get a "Chunk failed to load message", we want to reload the page
     * Same if we receive and Unexpected token of '<' message
     * This should force the browser to receive the latest version of index/bundles
     * We also want to make sure we don't force an excessive reload loop on the device if chunks keep failing
     * We will store the last "forced reload" time in local storage, to ensure we don't reload too many times
     * Otherwise, we want to let the default Error handler function from Angular run
     */

    // Checking for error
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    const unexpectedTokenMessage = /Unexpected token '<'/;
    if (chunkFailedMessage.test(error.message) || unexpectedTokenMessage.test(error.message)) {

      //Handling last forced reload
      const lastReload = window.localStorage.getItem(lastForcedChunkReloadKey);
      if (lastReload) {

        // Get elapsed time since last forced reload
        const oldTime = parseInt(lastReload, 10);
        const currentTime = new Date().getTime();
        const elapsedTimeInSeconds = Math.floor((currentTime - oldTime) / 1000);

        // If time elapsed is greater than 15 seconds, notify user of error and ask for manual reload
        if (elapsedTimeInSeconds >= 15) {
          // Nullify value in localstorage so on re-attempt by user, we do not block
          window.localStorage.removeItem(lastForcedChunkReloadKey);
          // Notify user of error, and stop attempting to reload
          alert('An Unexpected Error Has Occured: Please reload');
          return;
        }
      }
      else {
        window.localStorage.setItem(lastForcedChunkReloadKey, new Date().getTime().toString());
      }
      window.location.reload();
    }
    else {
      // Use default handleError function for all other errors
      super.handleError(error);
    }
  }
}

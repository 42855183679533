import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ElDeploymentTimeComponent } from './deployment-time.component';
import { AtomsModule } from '@library/ui/atoms/atoms.module';

@NgModule({
  declarations: [ElDeploymentTimeComponent],
  imports: [
    CommonModule,
    AtomsModule,
  ],
  exports: [
    ElDeploymentTimeComponent
  ]
})
export class ElDeploymentTimeModule { }

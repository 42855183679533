import { Injectable, InjectionToken } from '@angular/core';
import { SettingsState } from '@library/utils/interfaces/settings-state.interface';
import { Actions, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import * as RoomsActions from './rooms.action';
import { CreateRoom, DeleteMeasurementsPayload, GetMeasureCsvPayload, Room, RoomNameUpdate, RoomOffsetUpdatePayload, SetTrueTemperaturePayload } from './rooms.interface';
import * as RoomsSelectors from './rooms.selector';

export const ROOMS_FACADE = new InjectionToken('ROOMS_FACADE');

/**
 * Has to be extended by a specific facade for each project
 * Extending facade must have {providedIn: 'root'} in its Injectable decorator
 */
@Injectable()
export class RoomsFacade {
  rooms$ = this.store$.pipe(select(RoomsSelectors.getRooms));
  currentRoom$ = this.store$.pipe(select(RoomsSelectors.getCurrentRoom));
  thermMeasuredTemperature$ = this.store$.pipe(select(RoomsSelectors.getCurrentTemperature));
  modules$ = this.store$.pipe(select(RoomsSelectors.getRoomModules));
  currentRoomId$ = this.store$.pipe(select(RoomsSelectors.getCurrentRoomId));
  trueTemperatureAvailable$ = this.store$.pipe(select(RoomsSelectors.getTrueTemperatureAvailable));
  hasValve$ = this.store$.pipe(select(RoomsSelectors.hasValve));
  isCurrentRoomReachable$ = this.store$.pipe(select(RoomsSelectors.isCurrentRoomReachable));
  roomGateway$ = this.store$.pipe(select(RoomsSelectors.getRoomGateway));
  currentHomeRooms$ = this.store$.pipe(select(RoomsSelectors.getcurrentHomeRooms));

  constructor(
    protected store$: Store<SettingsState>,
    protected actions$: Actions
  ) { }

  getRoomById(roomId: string): Observable<Room> {
    if (cache[roomId]) {
      return cache[roomId];
    }
    else {
      cache[roomId] = this.store$.pipe(select(RoomsSelectors.getRoomById(roomId)));
      return cache[roomId];
    }
  }

  setCurrentRoom(roomId: Room["id"]): void {
    this.store$.dispatch(new RoomsActions.SelectRoom(roomId));
  }

  setTrueTemperature(params: SetTrueTemperaturePayload) {
    this.store$.dispatch(new RoomsActions.SetTrueTemperature(params));

    return this.actions$.pipe(
      ofType<RoomsActions.SetTrueTemperatureSuccess | RoomsActions.SetTrueTemperatureFailure>(
        RoomsActions.EnumRoomsActions.SetTrueTemperatureSuccess,
        RoomsActions.EnumRoomsActions.SetTrueTemperatureFailure,
      ),
      take(1)
    );
  }

  setRoomTemperatureOffset(params: RoomOffsetUpdatePayload[]) {
    this.store$.dispatch(new RoomsActions.UpdateRoomMeasureOffset(params));

    return this.actions$.pipe(
      ofType(RoomsActions.EnumRoomsActions.UpdateRoomMeasureOffsetSuccess),
      take(1)
    );
  }

  getMeasureCsv(params: GetMeasureCsvPayload) {
    this.store$.dispatch(new RoomsActions.GetMeasureCsv(params));

    return this.actions$.pipe(
      ofType<RoomsActions.GetMeasureCsvSuccess | RoomsActions.GetMeasureCsvFailure>(
        RoomsActions.EnumRoomsActions.GetMeasureCsvSuccess,
        RoomsActions.EnumRoomsActions.GetMeasureCsvFailure,
      ),
      take(1)
    );
  }

  getRoomMeasureCsv(params: GetMeasureCsvPayload) {
    this.store$.dispatch(new RoomsActions.GetRoomMeasureCsv(params));

    return this.actions$.pipe(
      ofType(RoomsActions.EnumRoomsActions.GetRoomMeasureCsvSuccess, RoomsActions.EnumRoomsActions.GetRoomMeasureCsvFailure),
      take(1)
    );
  }

  deleteMeasurements(params: DeleteMeasurementsPayload): Observable<Action> {
    this.store$.dispatch(new RoomsActions.DeleteMeasurements(params));

    return this.actions$.pipe(
      ofType(RoomsActions.EnumRoomsActions.DeleteMeasurementsSuccess, RoomsActions.EnumRoomsActions.DeleteMeasurementsFailure),
      take(1)
    );
  }

  deleteRoom(params: {home_id: string, room_id: string}) {
    this.store$.dispatch(new RoomsActions.DeleteRoom(params));

    return this.actions$.pipe(
      ofType<RoomsActions.DeleteRoomSuccess | RoomsActions.DeleteRoomFailure>(
        RoomsActions.EnumRoomsActions.DeleteRoomSuccess,
        RoomsActions.EnumRoomsActions.DeleteRoomFailure
      ),
      take(1)
    );
  }

  createNewRoom(params: CreateRoom, header?:{ [key: string]: string }) {

    this.store$.dispatch(new RoomsActions.CreateNewRoom(params, header));

    return this.actions$.pipe(
      ofType<RoomsActions.CreateNewRoomSuccess | RoomsActions.CreateNewRoomFailure>
      (RoomsActions.EnumRoomsActions.CreateNewRoomSuccess, RoomsActions.EnumRoomsActions.CreateNewRoomFailure),
      take(1)
    );
  }

  updateRoomName(params: RoomNameUpdate, header?:{ [key: string]: string }) {

    this.store$.dispatch(new RoomsActions.UpdateRoomName(params, header));

    return this.actions$.pipe(
      ofType<RoomsActions.UpdateRoomNameSuccess | RoomsActions.UpdateRoomNameFailure>
      (RoomsActions.EnumRoomsActions.UpdateRoomNameSuccess, RoomsActions.EnumRoomsActions.UpdateRoomNameFailure),
      take(1)
    );
  }
}

const cache = {};


import { NgModule } from '@angular/core';
import { ActivableIconComponent } from './activable-icon.component';
import { AtomsModule } from '@library/ui/atoms/atoms.module';

@NgModule({
  declarations: [
    ActivableIconComponent
  ],
  exports: [
    ActivableIconComponent
  ],
  imports: [
    AtomsModule
  ]
})
export class ActivableIconModule { }

import { Injectable } from '@angular/core';

import { UserAgentService } from './user-agent.service';

@Injectable({
  providedIn: 'root'
})
export class CallbackService {
  url: string;

  list = {
    tokenExpired : 'tokenExpired',
    logout : 'logout',
    setTitle : 'setTitle',
    changeCurrentTitle: 'changeCurrentTitle',
    didUpdateUserData : 'didUpdateUserData',
    shouldGoBack : 'shouldGoBack',
    enableBack: 'enableBack',
    enableClose: 'enableClose',
    forceBack : 'forceBack',
    endLoading : 'endLoading',
    startLoading : 'startLoading',
    quizzDone: 'quizzDone',
    quizzStart: 'quizzStart',
    quizzCloseSchedule: 'quizzCloseSchedule',
    needsWifiChange: 'needsWifiChange',
    needsHomekitInstall: 'needsHomekitInstall',
    needsHomekitConfigure: 'needsHomekitConfigure',
    needsNewModule: 'needsNewModule',
    needsNewInstall: 'needsNewInstall',
    shouldExit: 'shouldExit',
    didUpdateUserDataAndExit: 'didUpdateUserDataAndExit',
    shareIt: 'shareIt',
    canShare: 'canShare',
    openInBrowser: 'openInBrowser',
    download: 'download',
    updateFirmware: 'updateFirmware',
    onPageLoaded: 'onPageLoaded',
    disassociateMyStation: 'disassociateMyStation',
    disassociateOtherStation: 'disassociateOtherStation',
    configureWifi: 'configureWifi',
    calibrationSuccess: 'calibrationSuccess',
    calibrationFailure: 'calibrationFailure',
    shouldRemoveUser: 'shouldRemoveUser',
    onNacAlertAway: 'onNacAlertAway',
    onNacAlertHome: 'onNacAlertHome'
  };

  constructor(private agent: UserAgentService) {}

  setUrl(url) {
    this.url = url;
  }

  call = (name: string, args = null) => {
    if (this.agent.isNativeApp()) {
      let iframe = document.createElement('IFRAME');
      iframe.setAttribute('src', 'js-frame:' + name + ':0:' + encodeURIComponent(JSON.stringify(args)));
      document.documentElement.appendChild(iframe);
      iframe.parentNode.removeChild(iframe);
      iframe = null;
    }
    else {
      const msg = {id: name, params: args};
      window.parent.postMessage(msg, '*');
    }
  };
}

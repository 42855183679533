import { Room } from './rooms.interface';

const emptyRooms: Room[] = [];

export interface RoomsState {
  all: Room[];
  currentRoomId: string;
  loading: boolean;
}

export const initialRoomsState: RoomsState = {
  all: emptyRooms,
  currentRoomId: null,
  loading: false,
};

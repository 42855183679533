import { Pipe, PipeTransform } from '@angular/core';
import { EnumMeasureTypes } from '@library/store/measures/measures.interface';
import { UserFacade } from '@library/store/user/user.facade';
import { User } from '@library/store/user/user.interface';
import { Enums } from '@library/utils/constants/Enums.constant';
import { LangPipe } from '@library/utils/pipes/lang.pipe';
import { take } from 'rxjs';

@Pipe({
  name: 'toDisplayValue'
})
export class ToDisplayValuePipe implements PipeTransform {

  constructor(
    private userFacade: UserFacade,
    private langPipe: LangPipe,
  ) {}

  transform(value: number, measureType: EnumMeasureTypes): string {
    let user: User;
    this.userFacade.currentUser$.pipe(take(1)).subscribe(u => user = u);
    if (value === null || typeof value === "undefined") {
      return '--';
    }

    let res: string;

    switch (measureType) {

      case EnumMeasureTypes.TEMPERATURE:
      case EnumMeasureTypes.MAX_TEMPERATURE:
      case EnumMeasureTypes.MIN_TEMPERATURE:
      case EnumMeasureTypes.SP_TEMPERATURE:
        res = DISPLAY_CONVERSION.TEMPERATURE[user.unit_system](value);
        break;

      case EnumMeasureTypes.PRESSURE:
        res = DISPLAY_CONVERSION.PRESSURE[user.unit_pressure ?? 0](value);
        break;

      case EnumMeasureTypes.WIND_STRENGTH:
      case EnumMeasureTypes.GUST_STRENGTH:
        res = DISPLAY_CONVERSION.WIND_SPEED[user.unit_wind](value);
        break;

      case EnumMeasureTypes.WIND_ANGLE:
      case EnumMeasureTypes.GUST_ANGLE:
        res = this.langPipe.transform(toWindDirection(value));
        break;

      case EnumMeasureTypes.SUM_RAIN:
        res = DISPLAY_CONVERSION.RAIN[user.unit_system](value);
        break;

      case EnumMeasureTypes.SUM_ENERGY_ELEC_NO_CONTRACT:
      case EnumMeasureTypes.SUM_ENERGY_ELEC_BASE:
      case EnumMeasureTypes.SUM_ENERGY_ELEC_PEAK:
      case EnumMeasureTypes.SUM_ENERGY_ELEC_OFFPEAK:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_NO_CONTRACT:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_BASE:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PEAK:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_OFFPEAK:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_SUPER_OFFPEAK:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_WEEKEND:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_FREE:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_CUSTOM1:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_CUSTOM2:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_CUSTOM3:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_CUSTOM4:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_CUSTOM5:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_CUSTOM6:
      case EnumMeasureTypes.SUM_ENERGY_RESELL_TO_GRID:
      case EnumMeasureTypes.SUM_ENERGY_SELF_CONSUMPTION: {
        const kWh = value / 1000;
        res = kWh.toFixed(2);
        if (kWh >= 100) {
          res = kWh.toFixed(1);
        }
        if (kWh >= 1000) {
          res = kWh.toFixed(0);
        }
        break;
      }
      case EnumMeasureTypes.SUM_ENERGY_PRICE_BASE:
      case EnumMeasureTypes.SUM_ENERGY_PRICE_PEAK:
      case EnumMeasureTypes.SUM_ENERGY_PRICE_OFFPEAK:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_BASE:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_PEAK:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_OFFPEAK:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_SUPER_OFFPEAK:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_WEEKEND:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_FREE:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM1:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM2:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM3:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM4:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM5:
      case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM6:
      case EnumMeasureTypes.SUM_ENERGY_RESELL_TO_GRID_PRICE:
        res = value >= 1000 ? value.toFixed(0) : value.toFixed(2);
        break;
      case EnumMeasureTypes.SUM_FLUID_PRICE_GAS:
      case EnumMeasureTypes.SUM_FLUID_PRICE_HOT_WATER:
      case EnumMeasureTypes.SUM_FLUID_PRICE_COLD_WATER:
        res = value.toFixed(2);
        break;

      case EnumMeasureTypes.POWER_PROD_ELEC:
        res = (value > 1000) ? (value / 1000).toFixed(1) : value.toString();
        break;

      case EnumMeasureTypes.PERCENT_BOILER_ON:
      case EnumMeasureTypes.HEATING_POWER_REQUEST:
        res = Math.round(value).toString();
        break;

      default:
        res = value + '';
        break;
    }

    res = res.replace(/\.0+$/, '');

    return res;
  }

}

const DISPLAY_CONVERSION = {
  WIND_SPEED: {
    [Enums.WindUnit.KMH]: (speed: number) => speed.toFixed(1),
    [Enums.WindUnit.MPH]: (speed: number) => speed.toFixed(1),
    [Enums.WindUnit.MS]: (speed: number) => speed.toFixed(1),
    [Enums.WindUnit.BEAUFORT]: (speed: number) => speed.toFixed(0),
    [Enums.WindUnit.KNOT]: (speed: number) => speed.toFixed(1),
  },
  TEMPERATURE: {
    [Enums.SystemUnit.METRIC]: (temperature: number) => temperature.toFixed(1),
    [Enums.SystemUnit.IMPERIAL]: (temperature: number) => temperature.toFixed(1),
  },
  RAIN: {
    [Enums.SystemUnit.METRIC]: (rain: number) => rain.toFixed(2),
    [Enums.SystemUnit.IMPERIAL]: (rain: number) => rain.toFixed(3),
  },
  PRESSURE: {
    [Enums.PressureUnit.MBAR]: (pressure: number) => pressure.toFixed(0),
    [Enums.PressureUnit.MERCURY]: (pressure: number) => pressure.toFixed(1),
    [Enums.PressureUnit.TORR]: (pressure: number) => pressure.toFixed(1),
  },
};

function toWindDirection(windAngle: number) {
  let direction = 'N/A';

  if (windAngle === -1) {
    direction = 'station-app.__WIND_DIR_VARIABLE';
  }
  else if (windAngle >= 349 || windAngle <= 11) {
    direction = 'station-app.__WIND_DIR_N';
  }
  else if (windAngle >= 12 && windAngle <= 33) {
    direction = 'station-app.__WIND_DIR_NNE';
  }
  else if (windAngle >= 34 && windAngle <= 56) {
    direction = 'station-app.__WIND_DIR_NE';
  }
  else if (windAngle >= 57 && windAngle <= 78) {
    direction = 'station-app.__WIND_DIR_ENE';
  }
  else if (windAngle >= 79 && windAngle <= 101) {
    direction = 'station-app.__WIND_DIR_E';
  }
  else if (windAngle >= 102 && windAngle <= 123) {
    direction = 'station-app.__WIND_DIR_ESE';
  }
  else if (windAngle >= 124 && windAngle <= 146) {
    direction = 'station-app.__WIND_DIR_SE';
  }
  else if (windAngle >= 147 && windAngle <= 168) {
    direction = 'station-app.__WIND_DIR_SSE';
  }
  else if (windAngle >= 169 && windAngle <= 191) {
    direction = 'station-app.__WIND_DIR_S';
  }
  else if (windAngle >= 192 && windAngle <= 213) {
    direction = 'station-app.__WIND_DIR_SSW';
  }
  else if (windAngle >= 214 && windAngle <= 236) {
    direction = 'station-app.__WIND_DIR_SW';
  }
  else if (windAngle >= 237 && windAngle <= 258) {
    direction = 'station-app.__WIND_DIR_WSW';
  }
  else if (windAngle >= 259 && windAngle <= 281) {
    direction = 'station-app.__WIND_DIR_W';
  }
  else if (windAngle >= 282 && windAngle <= 303) {
    direction = 'station-app.__WIND_DIR_WNW';
  }
  else if (windAngle >= 304 && windAngle <= 326) {
    direction = 'station-app.__WIND_DIR_NW';
  }
  else if (windAngle >= 327 && windAngle <= 348) {
    direction = 'station-app.__WIND_DIR_NNW';
  }

  return direction;
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { PlaceInfos } from '@library/utils/interfaces/place-infos.interface';
import * as Map from 'mapbox.js';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LangPipe } from '../pipes/lang.pipe';

// set 'mapboxAccessToken: value' in environment file
export const MAPBOX_ACCESS_TOKEN = new InjectionToken('Access Token For Mapbox API');

interface MapboxPlacesResult {
  features: {
    id: string;
    place_name: string;
    center: [number, number];
    context: {
      id: string;
      short_code: string;
      text: string;
      place_name: string;
    }[];
  }[];
}

@Injectable({
  providedIn: 'root'
})
export class MapboxService {

  private mapBoxBaseApi = 'https://api.mapbox.com/geocoding/v5/mapbox.places/';

  constructor(
    public langPipe: LangPipe,
    @Inject(MAPBOX_ACCESS_TOKEN)
    public mapBoxAccessToken: string,
    public http: HttpClient,
  ) {
    Map.mapbox.accessToken = mapBoxAccessToken;
  }

  /**
   * Split feature or context string (from mapbox.places endpoint)
   * @param id ID of feature from endpoint
   */
  private getTypeId(id: string): string {
    return id.split('.')[0];
  }

  /**
   * Returns the address of a position given its coordinates
   * @param coordinates Coordinates of the position
   */
  getAddressByCoordinates(coordinates: Map.LatLng = new Map.LatLng(0, 0)): Observable<string> {
    return this.http.get(`
      ${this.mapBoxBaseApi}
      ${coordinates.lng},
      ${coordinates.lat}.json?access_token=${this.mapBoxAccessToken}`
    ).pipe(
      map((returnVal: MapboxPlacesResult) => {
        let address = this.langPipe.transform('common-settings.__NO_GEOLOCATION');
        try {
          returnVal.features.forEach((feature) => {
            if ((this.getTypeId(feature.id) === 'address') || (this.getTypeId(feature.id) === 'poi')) {
              address = feature.place_name;
            }
          });
        }
        catch (e) {
          console.error(e);
        }
        return address;
      })
    );
  }

  /**
   * Return place infos given an adress
   * @param text
   */
  getPlacesByText(text: string): Observable<PlaceInfos[]>{
    return this.http.get(`
      ${this.mapBoxBaseApi}
      ${text},
      .json?access_token=${this.mapBoxAccessToken}`).pipe(
      map((placeInfosRequest: MapboxPlacesResult) => {

        const placesInfos: Array<PlaceInfos> = [];

        placeInfosRequest.features.map((feature, index) => {
          const placeInfos = {} as PlaceInfos;
          placeInfos.address = feature.place_name;
          placeInfos.coordinates = new Map.LatLng(feature.center[0], feature.center[1]);
          if (feature.context) {
            feature.context.map(context => {
              switch (this.getTypeId(context.id)) {
                case 'address':
                  placeInfos.address = context.place_name;
                  break;
                case 'postcode':
                  placeInfos.postcode = context.text;
                  break;
                case 'place':
                  placeInfos.city = context.text;
                  break;
                case 'region':
                  placeInfos.region = context.text;
                  break;
                case 'country':
                  placeInfos.country = {
                    name: null,
                    short_code: null,
                  };
                  placeInfos.country.name = context.text;
                  placeInfos.country.short_code = context.short_code;
                  break;
              }
            });
          }
          placesInfos[index] = placeInfos;
        });

        return placesInfos;
      }),
      catchError((error) => {
        console.error(error);
        return of(null);
      })
    );
  }

  /**
   * Return map
   * @param mapId
   * @param style
   * @param options
   */
  createMap(mapId: string, style: string, options: object = {}) {
    options = Object.assign({
      minZoom: 3,
      keyboard: true,
      keyboardPanOffset: 1,
      inertia: false,
      zoomControl: false,
      zoom: 3,
      center: new Map.LatLng(0, 0)
    }, options);

    return Map.mapbox.map(mapId, style, options);
  }

  /**
   * Return marker
   * @param coordinates
   * @param options
   */
  createMarker(coordinates: Map.LatLng, options: object = {}) {
    options = Object.assign({
      icon: Map.mapbox.marker.icon({
        'marker-color': '1e76b5'
      }),
      draggable: true
    }, options);

    return Map.marker(coordinates, options);
  }

  createCustomMarker(iconUrl, coordinates: Map.LatLng, draggable: boolean, options: object = {}) {
    options = Object.assign({
      icon: new Map.icon({
        iconUrl,
        iconAnchor: [16, 28],
        iconSize: [32, 45]
      }),
      draggable
    }, options);
    return Map.marker(coordinates, options);
  }
}

import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {

  @Input() display = false;
  @Output() displayChange = new EventEmitter<boolean>();

  @HostBinding('class') get classes(): string {
    return this.display ? 'modal is-active' : 'modal';
  }

  close(event$: Event): void {
    event$.stopPropagation();
    this.displayChange.emit(false);
  }

}

import { Component, Input, HostBinding, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'lay-panel-block',
  templateUrl: './panel-block.component.html',
  styleUrls: ['./panel-block.component.sass'],
  encapsulation: ViewEncapsulation.None
})

export class PanelBlockComponent {
  @Input() styleClass = '';
  @Input() size = 'default';
  @Input() isBottomFixed;
  @Input() transparent = false;

  @HostBinding('class') get sizeClass() {
    return `panel-block is-${this.size} ${this.styleClass} ${this.transparent ? 'is-transparent-background is-borderless' : ''}`;
  }

}

/**
 * Used in Settings Legrand, Settings Lux
 */

import { GlobalErrorPayload } from './global-error.types';
import { GlobalErrorService } from './global-error.service';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Component, OnInit, HostListener, Input } from '@angular/core';

// To put ouside the route outlet
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'global-error-popup',
  templateUrl: './error-pop-up.component.html',
  styleUrls: ['./error-pop-up.component.scss']
})
export class ErrorPopUpComponent implements OnInit {

  errorDisplay = false;

  globalError$: Observable<GlobalErrorPayload>;

  // the default common settings filename is 'common-settings'
  @Input('common-settings')
  set commonSettingsFileName(fileName: string) {
    this.globalErrorService.commonSettingsFileName = fileName;
  }

  constructor(
    private globalErrorService: GlobalErrorService
  ) {
    this.globalError$ = this.globalErrorService.globalError$.pipe(
      tap(() => {
        this.errorDisplay = true;
      })
    );
  }

  ngOnInit() {
  }

  // take action or cancel
  @HostListener('window:popstate') // destroy the pop up if the user quits the current page
  onClick(buttonCallback?: () => void) {
    if (buttonCallback) {
      buttonCallback();
    }
    this.errorDisplay = false;
  }
}

import { createSelector } from '@ngrx/store';

const getLangs = (state) => state.langs;

export const getAllLangs = createSelector(getLangs, (state) => {
  const { ...langs } = state;
  return langs;
});

export const getLanguage = createSelector(getLangs, (state) => {
  return state.reg_locale.split('_')[0];
});

export const getRegLocale = createSelector(getLangs, (state) => {
  return state.reg_locale;
});

function get(obj, key) {
  return key.split('.').reduce((o, x) => {
    return (typeof o === 'undefined' || o === null) ? o : o[x];
  }, obj);
}

export const getTranslation = createSelector(getLangs, (state, props: {value, args: string[]}) => {
  if (props.value) {
    const value = props.value.split('.');

    let alias = 'app'; // default alias
    let key = value[0];
    // if alias is specify (e.g: common.__MY_KEY)
    // set new alias and shift it from value array
    // EDIT: removed shift, as compiler in settings projects was dropping function for some reason. bug with transpiler.
    // Does not effect all uses of shift, issue revolves around evaluating this function
    if (value.length > 1) {
      [alias, key] = value;
    }

    /**
       * Return the translation or the key if translation not found
       * replace dynamic variables if needed
       */
    let i = 0;

    const label = (
      get(state, `${alias}.${key}`) || `${key}`
    ).replace(/%s/g, () =>  {
      return props.args[i++];
    }).replace(/\\n/g, '<br/>');

    // Filter crowdin key
    return label.match(/__[A-Z_]+/) ? '' : label;
  }
  else {
    return '';
  }
});

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import {
  EnumLangActions, LoadLangs,
  LoadLangsFailure,
  LoadLangsSuccess
} from './lang.actions';

import { map } from 'rxjs/operators';
import { LangService } from './lang.service';

@Injectable()
export class LangEffects {
  getLangs$ = createEffect(() => this.actions$.pipe(
    ofType<LoadLangs>(EnumLangActions.LoadLangs),
    switchMap((action) => {
      const regLocale = action.payload['locale'];
      return this.langService.loadLangs(action.payload).pipe(
        map((langs) => new LoadLangsSuccess({ ...langs, reg_locale: regLocale })),
        catchError(({ error }) => of(new LoadLangsFailure(error))),
      );
    })
  ));

  constructor(
    private langService: LangService,
    private actions$: Actions
  ) { }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ElHeadingComponent } from './el-heading.component';
import { AtomsModule } from '@library/ui/atoms/atoms.module';

@NgModule({
  declarations: [ElHeadingComponent],
  imports: [
    CommonModule,
    AtomsModule,
  ],
  exports: [
    ElHeadingComponent
  ]
})
export class ElHeadingModule { }

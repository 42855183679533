import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    role: 'img'
  }
})
export class IconComponent {
  localName: string;

  @HostBinding('attr.dir') get hostClass() {
    return 'ltr';
  }

  @Input()
  set name(name) {
    this.localName = name;
  }

  get name(): string {
    return `icon-${this.localName}`;
  }

}

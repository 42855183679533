import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkComponent } from './link.component';
import { LinkUrlDirective } from './link-url.directive';
import { LinkCallbackDirective } from './link-callback.directive';
import { LinkRouterDirective } from './link-router.directive';
import { LayButtonModule } from '../button/button.module';
import { AtomsModule } from '@library/ui/atoms/atoms.module';

@NgModule({
  imports: [
    CommonModule,
    LayButtonModule,
    AtomsModule,
  ],
  exports: [
    LinkComponent,
    LinkUrlDirective,
    LinkCallbackDirective,
    LinkRouterDirective
  ],
  declarations: [LinkComponent, LinkUrlDirective, LinkCallbackDirective, LinkRouterDirective]
})
export class LinkModule { }

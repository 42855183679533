import { Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAgentService } from '@library/utils/services/user-agent.service';

@Directive({
  selector: '[route]'
})
export class LinkRouterDirective implements OnChanges {
  /**
   * route to go to
   */
  @Input() route: string | string[];

  /**
   * Query parameters
   */
  @Input() queryParams: { [key: string]: string };

  /**
   * Event emitted when the link is clicked
   */
  @Output() navigate$ = new EventEmitter();

  @Input()
    resolve = true;

  public hasClick = false;

  constructor(
    public userAgent: UserAgentService,
    public router: Router,
    private el: ElementRef,
    public activatedRoute: ActivatedRoute
  ) {}

  @HostListener('click', ['$event'])
  goTo(event) {
    if (!this.resolve) {
      event.preventDefault();
      event.stopPropagation();
      this.hasClick = true;
    }
    else {
      this.navigate();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.hasClick && changes.resolve && changes.resolve.currentValue) {
      this.navigate();
    }
  }

  navigate() {
    this.navigate$.next(null);
    let route: string[];

    if (typeof this.route === 'string') {
      route = [this.route];
    }
    else {
      route = this.route;
    }

    this.router.navigate(route, {
      queryParams: this.queryParams,
      relativeTo: this.activatedRoute,
      queryParamsHandling: 'merge'
    });
  }
}

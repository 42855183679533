import { HomesState } from '@library/store/homes/homes.state';
import { ModulesState } from '@library/store/modules/modules.state';
import { Enums } from '@library/utils/constants/Enums.constant';
import { SettingsState } from '@library/utils/interfaces/settings-state.interface';
import { createSelector } from '@ngrx/store';
import { Room } from '../rooms/rooms.interface';
import { getcurrentHomeRooms } from '../rooms/rooms.selector';
import { ElectricityPhase, Module } from './modules.interface';

const modulesState = (state: SettingsState) => state.modules;
const homesState = (state: SettingsState) => state.homes;

const currentHomeId = createSelector(
  homesState,
  (state: HomesState) => {
    return state.currentHomeId;
  }
);

export const getModules = createSelector(
  modulesState,
  (state: ModulesState) => {
    return state.all;
  }
);

export const getCurrentModuleId = createSelector(
  modulesState,
  (state: ModulesState) => {
    return state.currentModuleId;
  }
);

export const getCurrentModule = createSelector(
  getModules,
  getCurrentModuleId,
  (modules: Module[], moduleId: string) => {
    return modules.find(module => module.id === moduleId);
  }
);

export const getCurrentHomeModules = createSelector(
  currentHomeId,
  getModules,
  (currentHomeId: string, modules: Module[]) => {
    return modules?.filter(mod => mod.homeId === currentHomeId) ?? [];
  }
);

export const getModulesByType = (moduleTypes: string[]) => createSelector(
  getModules,
  (modules: Module[]) => {
    return modules.filter(mod => moduleTypes.includes(mod.type));
  }
);

export const getModulesById = (moduleIds: string[] = []) => createSelector(
  getModules,
  (modules: Module[]) => {
    return modules.filter(module => moduleIds.includes(module.id));
  }
);

export const getModuleById = (moduleId: string) => createSelector(
  getModules,
  (modules: Module[]) => {
    return modules.find(module => moduleId === module.id);
  }
);

export const isPowerLineEcometer = (m: Module) => m.type === 'NLE' && m.consumption_type === 'electrical' && m.customizable;
export const isPowerLineEnergyMeter = (m: Module) => m.type === 'NLPC' && m.electricity_phase ===	ElectricityPhase.NONE && m.measured_elec_type === 'consumption';
export const isPowerLineBnxm = (m: Module) => m.type === 'BNXM' && m.measured_elec_type === 'consumption' && m.measures_scope === 'device';
export const isPowerLineThreePhasesMeter = (module: Module) => module.type === 'NLY' && module.measures_scope === 'device';

export const getPowerLines = createSelector(
  getCurrentHomeModules,
  (modules: Module[]) => {
    return modules.filter(m => {
      return isPowerLineEcometer(m) ||
        isPowerLineEnergyMeter(m) ||
        isPowerLineThreePhasesMeter(m) ||
        isPowerLineBnxm(m);
    });
  }
);

export const getPhases = createSelector(
  getCurrentHomeModules,
  (modules: Module[]) => {
    const totalNLY = modules.find(m => m.type === 'NLY' && m.measures_scope === 'total');
    if (totalNLY) {
      return modules.filter(m => m.type === 'NLY' && m.id.includes(totalNLY.id) && m.id.includes('#'));
    }
    return [];
  }
);

export const getCurrentHomeModulesByRoomId = (roomId: string) => createSelector(
  getcurrentHomeRooms,
  getCurrentHomeModules,
  (rooms: Room[], modules: Module[]) => {
    const room: Room = rooms?.find(room => room.id === roomId);
    return modules?.filter(module => room?.modules?.includes(module.id)) ?? [];
  }
);

export const currentHomeBNMH = createSelector(
  getCurrentHomeModules,
  (modules: Module[]) => {
    return modules?.find(module => module.type === Enums.ModuleType.BNMH);
  }
);

const btGatewayList = [
  Enums.ModuleType.BNCX,
  Enums.ModuleType.BNMH,
  Enums.ModuleType.BNHY,
];

export const currentHomeBtGateway = createSelector(
  getCurrentHomeModules,
  (modules: Module[]) => {
    const btGateway = modules.find((module) => {
      return btGatewayList.includes(module.type);
    });
    return !!btGateway;
  }
);

const excludedModules = ['NLV', 'NLLV', 'NLIV', 'NLJ'];

export const getLiveAppliances = createSelector(
  getCurrentHomeModules,
  (modules: Module[]) => {
    return modules.filter(m => !!m.appliance_type && !excludedModules.includes(m.type));
  }
);

const graphsExcludedModules = ['BNSO'];

export const getGraphsAppliances = createSelector(
  getCurrentHomeModules,
  (modules: Module[]) => {
    return modules.filter(m => {
      return !!m.appliance_type &&
        !excludedModules.includes(m.type) &&
        !graphsExcludedModules.includes(m.type);
    });
  }
);

const reportGateways = [
  Enums.ModuleType.NAPLUG,
];

export const getCurrentHomeReportGateways = createSelector(
  getCurrentHomeModules,
  (currentHomeModules: Module[]) => {
    // all NAPlugs starter packs and thermostats ones
    return currentHomeModules.filter(module => {
      return reportGateways.includes(module.type);
    });
  }
);

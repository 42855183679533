import { Injectable } from '@angular/core';
import { NgrxHttpClient } from '@library/utils/services/http-service';

@Injectable()
export class SchedulesService {
  constructor(private http: NgrxHttpClient) {
  }

  createNewSchedule(params) {
    return this.http.post('createnewhomeschedule', params);
  }

  modifySchedule(params) {
    return this.http.post('synchomeschedule', params);
  }

  switchHomeSchedule(params) {
    return this.http.get('switchhomeschedule', params);
  }

  deleteschedule(params) {
    return this.http.get('deleteschedule', params);
  }
}

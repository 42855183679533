import { NgModule } from '@angular/core';
import { StepSliderComponent } from './step-slider.component';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UtilsService } from '@library/utils/services/utils.service';
import { AtomsModule } from '@library/ui/atoms/atoms.module';

@NgModule({
  declarations: [StepSliderComponent],
  imports: [
    CommonModule,
    FormsModule,
    AtomsModule,
  ],
  exports: [StepSliderComponent],
  providers: [
    UtilsService,
  ]
})
export class StepSliderModule { }

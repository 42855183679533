import { Directive, EventEmitter, HostListener, Inject, InjectionToken, Input, Optional, Output } from '@angular/core';
import { Share } from '@library/utils/interfaces/general-interfaces.interface';
import { CallbackService } from '@library/utils/services/callback.service';
import { UserAgentService } from '@library/utils/services/user-agent.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

interface ShareNavigator extends Navigator {
  share: (data: object) => Promise<void>;
}

export const USE_SHAREIT = new InjectionToken('USE_SHAREIT');

export enum HAS_SHARED {
  native = 'native',
  fallback = 'fallback',
  aborted = 'aborted',
}

@Directive({
  selector: '[share]',
})
export class ShareDirective {

  @Input() share: Share;
  @Input() shareAsync: Observable<Share>;
  @Output() hasShared = new EventEmitter<string>();

  constructor(
    private userAgentService: UserAgentService,
    private callbackService: CallbackService,
    @Optional() @Inject(USE_SHAREIT) private useShareIt: boolean
  ) {
    // Should use 'shareIt' callback by default.
    // Older applications should inject token and set to value to "false" if using shareIt is not possible
    if (this.useShareIt === null) {
      this.useShareIt = true;
    }
  }

  /**
  * This share directive attempts to use the Web API Share method to natively share content.
  * If this API is not supported by a browser, we will fallback to sharing by copying content to devices clipboard
  */
  @HostListener('click')
  shareContent() {
    if (this.share) {
      this.nativeShareMethod(this.share);
    }
    else {
      this.shareAsync.pipe(
        take(1)
      ).subscribe((data: Share) => {
        this.nativeShareMethod(data);
      });
    }
  }

  async nativeShareMethod(data: Share) {
    const navigator = window.navigator as ShareNavigator;
    /**
    * Not all browsers offer Share API.
    * For those browsers, we instead need to use the fallback method of sharing
    * We should only try this on mobile, as on desktop browsers this share option becomes very limitting
    * This means we only use the fallback on Desktop Chrome, Firefox, Safari, etc. despite their potential allowance of the Share API
    */
    if (
      navigator.share &&
      this.userAgentService.isMobile()
    ) {
      try {
        await navigator.share(data);
        this.hasShared.emit(HAS_SHARED.native);
      }
      catch (err) {
        // Shareit Callback only necessary on iOS, due to Apples implementation of Share API
        // Apple implementation does not allow HTTP calls to be made during the same callstack as Share API call
        // NotAllowedError refers to security error thrown when opening the Share API is disallowed by the browser
        if (
          err.name === 'NotAllowedError' &&
          this.userAgentService.isIos() &&
          this.userAgentService.isNativeApp() &&
          this.useShareIt
        ) {
          this.callbackService.call('shareIt', { text: data.text + '%0D%0A' + data.url });
          this.hasShared.emit(HAS_SHARED.native);
          // If opening share drawer fails for reason other than Security, or user closing drawer without sharing, use fallback method
          // AbortError refers to error thrown when user closes Share drawer without sharing anything
        }
        else if (err.name !== 'AbortError') {
          this.fallbackShareMethod(data);
        }
        else {
          this.hasShared.emit(HAS_SHARED.aborted);
        }

        console.error(err);
      }
      /**
      * As of Android 10, Anroid webview does not include support for Web Share API.
      * Need to handle new shareIt callback from our native Android apps
      */
    }
    else if (
      this.userAgentService.isAndroid() &&
      this.userAgentService.isNativeApp() &&
      this.useShareIt
    ) {
      this.callbackService.call('shareIt', { text: data.text + '%0D%0A' + data.url });
      this.hasShared.emit(HAS_SHARED.native);
    }
    else {
      this.fallbackShareMethod(data);
    }
  }

  // Fallback share method is copying invitation to devices clipboard, so the user may post it wherever they wish
  fallbackShareMethod(data: Share) {
    // Generate text
    let text = data.text;
    // If URL already exists in text, we do not want to add it twice
    // Determine if URL exists in text already, if not, append to the end of text
    if (data.url && !text.includes(data.url)) {
      text = text + '\n' + data.url;
    }

    // Due to a bug on copying in the clipboard in safari, we change the way to copy in the clipboard
    setTimeout(async () => await navigator.clipboard.writeText(text));

    this.hasShared.emit(HAS_SHARED.fallback);
  }
}
